import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../Logo/Logo';
import { I18nContext } from '../../I18n';
import MenuItem from '@material-ui/core/MenuItem';

import './TopMenu.scss';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import { ClickAwayListener, MenuList, Popper } from '@material-ui/core';
import { isEmpty, logOutTheUser } from '../../_lib/util';
import { defaultParticipant } from '../../_lib/constant';
import { getConfigFromLocalStorage } from '../Config/ConfigWrapper';
import AuthTopMenu from './AuthTopMenu';
import { publicApiMapping } from '../../hooks/useQueryCustomHooks/usePublicQueryStates';
import { useGetQueryCacheData } from '../../hooks/useQueryCustomHooks/useGetQueryCacheData';
import apiToUrlMap from '../../ApiMapping';
import { pathOverrideKeys } from '../../hooks/useQueryCustomHooks/useDataService';
import { PXNSettings } from '../../types/StockListTypes';
import { IAuthority } from '../../hooks/useQueryCustomHooks/useGetUserInfoAndAuthorities';
import { IUserInfo as UserInfoType } from '../../types/IUserInfo';
const Dialog = React.lazy(() => import('../Dialog/Dialog'));

interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  userId: number;
  isBuyer: boolean;
  isUserViewOnly?: boolean;
  enabledTags?: Array<string> | Record<string, boolean>;
  setupCompleted: boolean;
}

interface IPopOverMenu {
  element: HTMLElement;
  type: 'accountMenu' | 'buyerMenu';
}

function TopMenu() {
  const I18n = useContext(I18nContext);
  const inventorySettings = useGetQueryCacheData<any>(apiToUrlMap.inventorySettings);
  const pxnSettings: PXNSettings = useGetQueryCacheData<any>(
    apiToUrlMap.stockListSettings,
    pathOverrideKeys[apiToUrlMap.stockListSettings]
  )?.pxnSettings;
  const authorities = useGetQueryCacheData<IAuthority>(apiToUrlMap.authorities)?.authorityObj;

  const [userData, setUserData] = useState<IUserInfo>();

  const isPendoInitialized = useRef<boolean>(false);

  const [popoverMenu, setPopOverMenu] = React.useState<IPopOverMenu>();
  const [logoutDialog, setLogoutDialog] = React.useState<any>();

  const userState = useGetQueryCacheData(apiToUrlMap.getUserInfo) as UserInfoType;
  const configState = useGetQueryCacheData<any>(publicApiMapping.config);
  const salesOrder = useGetQueryCacheData<any>(
    apiToUrlMap.orderSettings,
    pathOverrideKeys[apiToUrlMap.orderSettings]
  );

  const getStringColorCode = (str: string) => {
    return str ? str[0].charCodeAt(0) : 0;
  };

  const fetchUserData = async () => {
    // calculate user initial color
    const userInfo = userState.userInfo;
    const elem: any = document.querySelector('nav.px-c-TopMenu');
    if (elem) {
      const colorNumber =
        Math.abs(
          (getStringColorCode(userInfo.firstName) + getStringColorCode(userInfo.lastName)) % 6
        ) + 1;
      elem.style.setProperty('--px_user_data_color', `var(--px_color_badge_${colorNumber})`);
    }
    setUserData(userInfo);
  };

  useEffect(() => {
    const getPxnParticipant = (pxnSettings: any) => {
      if (pxnSettings.isPxnr && pxnSettings.isPxns) return 'Supplier_Reseller';
      else if (pxnSettings.isPxnr) return 'Reseller';
      else return 'Supplier';
    };

    if (
      isEmpty(inventorySettings) ||
      isEmpty(salesOrder) ||
      isEmpty(userData) ||
      isEmpty(pxnSettings) ||
      isPendoInitialized.current
    )
      return;

    const newWindowObject = window as any;
    const pendo = newWindowObject.pendo;
    const ispxcParticipant = getConfigFromLocalStorage().networkParticipant;
    const participantId =
      ispxcParticipant && pxnSettings && pxnSettings.isPxnr
        ? +pxnSettings.pxnrParticipantId
        : defaultParticipant;
    const orderSettings = salesOrder[participantId];
    const visitor = {
      id: userData['userId'],
      role: 'Buyer',
      isBuyer: userData['isBuyer'],
      Inventory_Sync_Type: inventorySettings?.inventoryUpdateMethod,
      Inventory_Spdate_Source: inventorySettings?.inventoryUpdateSource,
      Order_Sync_Type: orderSettings?.salesOrderUpdateMethod,
      PXCParticipant: ispxcParticipant ? getPxnParticipant(pxnSettings) : null,
    };
    const account = {
      id: `${configState?.tenant_id}_StoreApp_${process.env.REACT_APP_STAGE}`,
    };
    // in your authentication promise handler or callback
    pendo.initialize({
      visitor,
      account,
    });
    isPendoInitialized.current = true;
  }, [pxnSettings, userData, configState.tenant_id]);

  useEffect(() => {
    if (userState?.isSignedIn && userState?.userInfo) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState?.isSignedIn, userState?.userInfo]);

  const openMenu = (e: any) => {
    setPopOverMenu({ element: e.target, type: 'buyerMenu' });
  };
  const openLogoutDialog = (e: any) => {
    closeMenu();
    setLogoutDialog(!!e);
  };

  const closeLogoutDialog = () => {
    closeMenu();
    setLogoutDialog(undefined);
  };

  const closeMenu = async () => {
    setPopOverMenu(undefined);
  };

  const logout = async () => {
    closeLogoutDialog();
    try {
      await logOutTheUser(I18n.logoutSuccessMessage || 'You logged out successfully!', 'success');
    } catch (error: any) {
      console.error('error signing out: ', error);
    }
  };

  return (
    <>
      <h1 className="show-for-sr text-center">{configState.tenantShortName || 'ACME'} SASS</h1>
      <nav className="px-c-TopMenu grid-cols-12">
        <Link to="/">
          <Logo />
        </Link>
        {/* show only LOGOUT button for prospects */}
        {(userState.requiresAccountSetup ||
          userState.gracePeriodBuyer ||
          (userData && !userData.setupCompleted)) && (
          <TertiaryButton onClick={openLogoutDialog} id="onboarding-logout">
            {I18n.logout || 'Logout'}
          </TertiaryButton>
        )}
        {/* show the regular menu for buyers who have completed the customer registration process */}
        {userState.isSignedIn && userData && userData.setupCompleted ? (
          <AuthTopMenu configState={configState} userData={userData} openMenu={openMenu} />
        ) : (
          <></>
        )}
      </nav>
      {popoverMenu && (
        <Popper
          open={!!popoverMenu.element}
          placement="bottom-start"
          className="px-popper-menu px-accounts-menu"
          disablePortal={true}
          transition
          keepMounted={false}
          anchorEl={popoverMenu.element}
        >
          <ClickAwayListener onClickAway={closeMenu}>
            <MenuList>
              <MenuItem
                data-id="accountDetailsBtn"
                component={Link}
                to="/account-details"
                onClick={closeMenu}
              >
                {I18n.accountDetails || 'My Account'}
              </MenuItem>
              {authorities && authorities.CAN_VIEW_ACCOUNT_BALANCE && (
                <MenuItem
                  data-id="accountDetailsBtn"
                  component={Link}
                  to="/account-details/account-balance"
                  onClick={closeMenu}
                >
                  {I18n.myAccountBlance || 'My Account Balance'}
                </MenuItem>
              )}
              <MenuItem
                data-id="myPreferencesBtn"
                component={Link}
                to="/preferences"
                onClick={closeMenu}
              >
                {I18n.myPreferences || 'My Preferences'}
              </MenuItem>
              <MenuItem data-id="logoutBtn" onClick={openLogoutDialog}>
                <span className="link">{I18n.logout || 'Logout'}</span>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Popper>
      )}
      {logoutDialog && (
        <React.Suspense fallback={<></>}>
          <Dialog
            isDialogOpen={!!logoutDialog}
            closeDialog={closeLogoutDialog}
            label={I18n.logout || 'Logout'}
            title={I18n.logout || 'Logout'}
            content={I18n.logoutConfirmationMessage || 'Are you sure you want to log out?'}
            actions={
              <>
                <TertiaryButton data-id="cancel" onClick={closeLogoutDialog}>
                  {I18n.cancel || 'Cancel'}
                </TertiaryButton>
                <PrimaryButton data-id="confirm" onClick={logout}>
                  {I18n.logout || 'Logout'}
                </PrimaryButton>
              </>
            }
          />
        </React.Suspense>
      )}
    </>
  );
}

export default TopMenu;
