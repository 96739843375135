import React from 'react';
import { NavLink, useLocation } from 'react-router-dom/';
import { I18nContext } from '../../I18n';
import './SideMenu.scss';

// Icons
// import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
// import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
// import GroupIcon from '@material-ui/icons/Group';
import ReorderIcon from '@material-ui/icons/Reorder';
// import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
// import AllInboxIcon from '@material-ui/icons/AllInbox';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
// import PersonIcon from '@material-ui/icons/Person';
// import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import { IconButton } from '@material-ui/core';
// import WebIcon from '@material-ui/icons/Web';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ViewListIcon from '@material-ui/icons/ViewList';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Tooltip from '@material-ui/core/Tooltip';
import Gavel from '@material-ui/icons/Gavel';
import { isViewOnlyCustomer } from '../../_lib/tagUtil';
import { OrderApprove } from '../../_lib/icons';
import { publicApiMapping } from '../../hooks/useQueryCustomHooks/usePublicQueryStates';
import { useGetQueryCacheData } from '../../hooks/useQueryCustomHooks/useGetQueryCacheData';
import apiToUrlMap from '../../ApiMapping';
import { IUserInfo } from '../../types/IUserInfo';

function SideMenu(props: any) {
  const I18n = React.useContext(I18nContext);
  const location = useLocation();

  const [sideMenuState, setSideMenuState] = React.useState(false);
  const [sideMenuSmallDeviceState, setSideMenuSmallDeviceState] = React.useState(false);
  const userState = useGetQueryCacheData(apiToUrlMap.getUserInfo) as IUserInfo;
  const configState = useGetQueryCacheData<any>(publicApiMapping.config);
  const menuItemDisplay = useGetQueryCacheData<any>('/px-api-gateway/security/authorities')
    .sideMenuState;

  const onSideMenuItemsClick = (e: any, pathname: any) => {
    if (location.pathname === pathname) {
      e.preventDefault();
      return;
    }
    setSideMenuSmallDeviceState(false);
  };

  return (
    <>
      <ul className="px-menu-mobile show-for-sm-only border-top">
        <li>
          <span
            onClick={() => {
              setSideMenuSmallDeviceState(!sideMenuSmallDeviceState);
            }}
            className="link"
          >
            <ReorderIcon />
          </span>
        </li>
        <li className="float-right">
          <NavLink
            to="/stock-alerts"
            activeClassName="px-active-navlink"
            onClick={(e: any) => onSideMenuItemsClick(e, '/stock-alerts')}
          >
            <NotificationsIcon />
            <small className="block">{I18n.alert || 'Alert'}</small>
          </NavLink>
        </li>
      </ul>
      <div className="px-sideMenu-sticky-container">
        <div
          onClick={() => {
            setSideMenuSmallDeviceState(false);
          }}
          className={`px-sideMenu-backdrop ${sideMenuSmallDeviceState ? 'show' : 'hide'}`}
        ></div>
        <nav
          className={`px-c-SideMenu px-menu-autoclose
          ${sideMenuState ? 'show-menu' : ''}
          ${sideMenuSmallDeviceState ? 'show-menu-small-device' : ''}
        `}
          onMouseEnter={() => {
            setSideMenuState(true);
          }}
          onMouseLeave={() => {
            setSideMenuState(false);
          }}
        >
          <MenuList className="padding-0 m-b-px-8">
            <MenuItem className="px-expand-side-menu padding-0 cell grid-x align-center text-center">
              <IconButton
                onClick={() => {
                  setSideMenuState(!sideMenuState);
                }}
                aria-label="expand/collapse"
                size="small"
                className="p-t-px-8 cell"
                disableRipple
              >
                <MenuIcon className="px-expand-menu-icon" />
              </IconButton>
            </MenuItem>
            <MenuItem
              className="px-menu-desk padding-0 border-bottom px-sideMenu-heading"
              disableRipple
            >
              <NavLink
                to="/"
                className="cell grid-x align-left align-middle px-sideMenu-item"
                onClick={(e: any) => {
                  e.preventDefault();
                  setSideMenuState(false);
                  setSideMenuSmallDeviceState(false);
                }}
              >
                <MenuOpenIcon className="px-sideMenu-item-icon" />
                <Tooltip
                  classes={{ popper: 'px-app-version-tooltip' }}
                  title={<span className="px-app-version">Version {configState?.appVersion}</span>}
                  defaultChecked={true}
                  TransitionProps={{ timeout: 0 }}
                  interactive
                >
                  <h5 className="margin-bottom-0">{I18n.menu || 'Menu'}</h5>
                </Tooltip>
              </NavLink>
            </MenuItem>
          </MenuList>
          <div className="px-menu-desk">
            {userState?.userInfo?.isOfflineBuyer ? (
              <MenuList>
                <MenuItem className={`border-top ${menuItemDisplay['Orders']} padding-0`}>
                  <NavLink
                    to="/orders"
                    activeClassName="px-active-navlink"
                    className="cell grid-x align-left align-middle px-sideMenu-item"
                    onClick={(e: any) => onSideMenuItemsClick(e, '/orders')}
                  >
                    <ReceiptIcon className="px-sideMenu-item-icon" />
                    <span>{I18n.orders || 'Orders'}</span>
                  </NavLink>
                </MenuItem>
              </MenuList>
            ) : isViewOnlyCustomer(userState?.userInfo?.enabledTags) ? (
              <>
                <p className="px-sideMenu-section-break m-b-px-6">
                  {I18n.stockList || 'Stock List'}
                </p>
                <MenuList className="padding-0">
                  <MenuItem className={`${menuItemDisplay['StockList']} padding-0 border-bottom`}>
                    <NavLink
                      to="/stock"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/stock')}
                    >
                      <ViewListIcon className="px-sideMenu-item-icon" />
                      <span>{I18n.stockList || 'Stocklist'}</span>
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={`${menuItemDisplay['Orders']} padding-0`}>
                    <NavLink
                      to="/orders"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/orders')}
                    >
                      <ReceiptIcon className="px-sideMenu-item-icon" />
                      <span>{I18n.orders || 'Orders'}</span>
                    </NavLink>
                  </MenuItem>
                </MenuList>
              </>
            ) : (
              <>
                <p className="px-sideMenu-section-break m-b-px-6">
                  {I18n.stockList || 'Stock List'}
                </p>
                <MenuList className="padding-0">
                  <MenuItem className={`${menuItemDisplay['StockList']} padding-0`}>
                    <NavLink
                      to="/stock"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/stock')}
                    >
                      <ViewListIcon className="px-sideMenu-item-icon" />
                      <span>{I18n.stockList || 'Stocklist'}</span>
                    </NavLink>
                  </MenuItem>
                  {/* Show cart page only for buyers who have completed the customer registration process */}
                  {userState.userInfo?.setupCompleted && (
                    <MenuItem className={`${menuItemDisplay['Cart']} padding-0 border-bottom`}>
                      <NavLink
                        to="/cart"
                        activeClassName="px-active-navlink"
                        className="cell grid-x align-left align-middle px-sideMenu-item"
                        onClick={(e: any) => onSideMenuItemsClick(e, '/cart')}
                      >
                        <ShoppingCartIcon className="px-sideMenu-item-icon" />
                        <span>{I18n.cartAndOffers || 'Cart and Offers'}</span>
                      </NavLink>
                    </MenuItem>
                  )}
                </MenuList>
                <MenuList className="padding-0">
                  <MenuItem className={`${menuItemDisplay['Auctions']} border-bottom padding-0`}>
                    <NavLink
                      to="/auctions"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/auctions')}
                    >
                      <Gavel className="px-sideMenu-item-icon" />
                      <span>{I18n.auctions || 'Auctions'}</span>
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={`${menuItemDisplay['Preorders']} border-bottom padding-0`}>
                    <NavLink
                      to="/po-awards"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/po-awards')}
                    >
                      <OrderApprove className="px-sideMenu-item-icon" />
                      <span>{I18n.poAwards || 'PO Awards'}</span>
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={`border-top ${menuItemDisplay['Orders']} padding-0`}>
                    <NavLink
                      to="/orders"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/orders')}
                    >
                      <ReceiptIcon className="px-sideMenu-item-icon" />
                      <span>{I18n.orders || 'Orders'}</span>
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={`border-top ${menuItemDisplay['Returns']} padding-0`}>
                    <NavLink
                      to="/returns"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => onSideMenuItemsClick(e, '/returns')}
                    >
                      <AssignmentReturnIcon className="px-sideMenu-item-icon" />
                      <span>{I18n.returns || 'Returns'}</span>
                    </NavLink>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </div>
        </nav>
      </div>
    </>
  );
}

export default SideMenu;
